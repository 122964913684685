import React, {FunctionComponent} from "react";
import SpeedDial from "@material-ui/lab/SpeedDial";
import SpeedDialIcon from "@material-ui/lab/SpeedDialIcon";
import SpeedDialAction from "@material-ui/lab/SpeedDialAction";
import {Chat, ScreenShare} from "@material-ui/icons";
import {makeStyles} from "@material-ui/core/styles";
import {Badge, useMediaQuery} from "@material-ui/core";
import StopScreenShareIcon from "@material-ui/icons/StopScreenShare";
import DescriptionIcon from "@material-ui/icons/Description";
import MicIcon from '@material-ui/icons/Mic';
import MicOffIcon from '@material-ui/icons/MicOff';

const styles = makeStyles((theme) => ({
    speedDial: {
        margin: theme.spacing(1),
        position: "fixed",
        bottom: theme.spacing(2),
        right: theme.spacing(3),
        opacity: 1,
        zIndex: 9,
        "& .MuiFab-primary": {
            color: theme.palette.background.default,
        },
    },
}));

interface SpeedDialButtonsProps {
    speedDialOpen: boolean;
    onOpenSpeedDial: () => void;
    onCloseSpeedDial: () => void;
    onStartChat: () => void;
    chatOpen: boolean;
    chatMsgInvisible: boolean;
    onShowDescription: () => void;
    onShareScreen: () => void;
    shareScreenOn: boolean;
    i18nText: any;
    connected: boolean;
    isMuted: boolean;
    onMuteMicrophone: () => void;
}

const SpeedDialButtons: FunctionComponent<SpeedDialButtonsProps> = ({
                                                                        speedDialOpen,
                                                                        onOpenSpeedDial,
                                                                        onCloseSpeedDial,
                                                                        onStartChat,
                                                                        chatOpen,
                                                                        chatMsgInvisible,
                                                                        onShowDescription,
                                                                        onShareScreen,
                                                                        shareScreenOn,
                                                                        i18nText,
                                                                        connected,
                                                                        isMuted,
                                                                        onMuteMicrophone
                                                                    }) => {
    // Styling
    const classes = styles();
    const desktop = useMediaQuery("(min-width:900px)");

    return (
        <SpeedDial
            className={classes.speedDial}
            FabProps={{size: "large"}}
            color={"white"}
            ariaLabel="SpeedDial example"
            icon={
                <Badge color="error" badgeContent={""} invisible={chatMsgInvisible}>
                    <SpeedDialIcon/>
                </Badge>
            }
            onOpen={onOpenSpeedDial}
            onClose={onCloseSpeedDial}
            open={speedDialOpen}
            hidden={chatOpen}
        >
            <SpeedDialAction
                FabProps={{size: "large"}}
                key="chat"
                icon={
                    <Badge color="error" badgeContent={""} invisible={chatMsgInvisible}>
                        <Chat/>
                    </Badge>
                }
                tooltipTitle={i18nText.tooltip.chat}
                onClick={onStartChat}
            />
            {connected && desktop && (
                <SpeedDialAction
                    FabProps={{size: "large"}}
                    key="share"
                    icon={shareScreenOn ? <StopScreenShareIcon/> : <ScreenShare/>}
                    onClick={onShareScreen}
                    tooltipTitle={
                        !shareScreenOn
                            ? i18nText.tooltip.screenShare
                            : i18nText.stopRecording
                    }
                />
            )}
            <SpeedDialAction
                FabProps={{size: "large"}}
                key="description"
                icon={<DescriptionIcon/>}
                onClick={onShowDescription}
                tooltipTitle={i18nText.tooltip.info}
            />
            <SpeedDialAction
                FabProps={{size: "large"}}
                key="muteMicrophone"
                icon={isMuted ? <MicOffIcon style={{color: "red"}}/> :
                    <MicIcon style={{color: connected ? "inherit" : "grey"}}/>}
                onClick={() => {
                    if (connected) {
                        onMuteMicrophone()
                    }
                }}
                tooltipTitle={
                    isMuted ?
                        i18nText.tooltip.muted
                        : i18nText.tooltip.unMuted
                }
            />
        </SpeedDial>
    );
};

export default SpeedDialButtons;
