import React, { FunctionComponent, useEffect, useRef } from "react";
import { Participant } from "twilio-video";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  videoBox: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    maxHeight: 350,
    borderRadius: 12,
    "& video": {
      maxWidth: "100%",
      maxHeight: 350,
      borderRadius: 12,
      [theme.breakpoints.between("lg", "xl")]: {
        height: 450,
      },
      [theme.breakpoints.down("md")]: {
        width: 500,
        maxHeight: 350,
      },
      [theme.breakpoints.down("sm")]: {
        width: 400,
        maxHeight: 270,
      },
      [theme.breakpoints.down("xs")]: {
        width: 300,
        maxHeight: 202,
      },
    },
  },
}));

interface ParticipantLoudestProps {
  participant: Participant;
}

const ParticipantLoudest: FunctionComponent<ParticipantLoudestProps> = ({
  participant,
}) => {
  const classes = useStyles();
  const videoRef = useRef<HTMLElement>();
  const removeTracks = () => {
    const current = videoRef.current;
    if (current != null) {
      while (current && current.firstChild) {
        current.removeChild(current.firstChild);
      }
    }
  };
  const renderTracks = (p: Participant) => {
    p.videoTracks.forEach((pub) => {
      if (pub.kind === "video" && pub.track) {
        (videoRef?.current as HTMLElement).appendChild(pub.track.attach());
      }
    });
  };

  useEffect(() => {
    const handler = () => {
      removeTracks();
      renderTracks(participant);
    };
    renderTracks(participant);
    participant.on("trackSubscribed", handler);

    participant.on("trackUnsubscribed", handler);

    return () => {
      participant.removeListener("trackSubscribed", handler);
      participant.removeListener("trackUnsubscribed", handler);
      removeTracks();
    };
  }, [participant]);
  // @ts-ignore
  return <div className={classes.videoBox} ref={videoRef} />;
};

export default ParticipantLoudest;
