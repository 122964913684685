import React from "react";
// @ts-ignore
import roomPic from '../assets/BM-logo.jpg'
import {makeStyles} from '@material-ui/core/styles';
import {isValidURL} from "./utils";

const useStyles = makeStyles(() => ({
        placeholder: {
            margin: "1em",
            height: "auto",
            maxWidth: "320px",
            justifyContent: "center",
        },
        placeholderImg: {
            width: "100%",
            height: "100%"
        }
    })
)

interface RoomPicProps {
    src: string
}

const PlaceHolderRoomPic: React.FC<RoomPicProps> = ({src}) => {
    const classes = useStyles()

    return <div className={classes.placeholder}>
        <img src={isValidURL(src) ? src : roomPic} className={classes.placeholderImg} alt="Placeholder room"/>
    </div>
}
export default PlaceHolderRoomPic;
