import React, { FunctionComponent } from "react";

import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  button: {
    padding: 20,
    fontSize: "large",
    margin: 10,
    width: 240,
    zIndex: 9,
    [theme.breakpoints.down("md")]: {
      margin: 10,
    },
  },
}));

interface RoomButtonProps {
  onButtonClick?: () => void;
  buttonText?: string;
  loading?: boolean;
  fullRoom?: boolean;
  expired?: boolean;
}

const RoomButton: FunctionComponent<RoomButtonProps> = ({
  onButtonClick,
  buttonText,
  loading,
  fullRoom,
  expired,
}) => {
  const classes = useStyles();

  const handleClick = () => {
    if (onButtonClick) {
      onButtonClick();
    }
  };

  return (
    <Button
      className={classes.button}
      size="large"
      variant="contained"
      color="primary"
      onClick={handleClick}
      disabled={loading || fullRoom || expired}
    >
      {buttonText}
    </Button>
  );
};

export default RoomButton;
