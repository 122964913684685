import React, { FC } from "react";
import { Room } from "twilio-video";
import Chat from "./Chat/index";
import Helper from "./Helper/Helper";
import ParticipantList from "./Participant/ParticipantList";

interface HomeScreenProps {
  room?: Room;
  i18nText: any;
  username: string;
  chatOpen: boolean;
  onChatClose?: () => void;
  onMsgSent?: (msgSent: boolean) => void;
}

const HomeScreen: FC<HomeScreenProps> = ({
  room,
  i18nText,
  username,
  chatOpen,
  onChatClose,
  onMsgSent,
}) => {
  return (
    <div style={{paddingBottom: 50, marginBottom:'4em'}}>
      {/* Show all participants */}
      {room && <ParticipantList room={room} />}

      {/* Show chat component*/}
      <Chat
        username={username}
        chatPlaceholder={i18nText.chatPlaceholder}
        chatOpen={chatOpen}
        onChatClose={onChatClose}
        onMsgSent={onMsgSent}
      />

      {/*Show list of participants (from backend)*/}
      <Helper />
    </div>
  );
};

export default HomeScreen;
