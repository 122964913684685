import React, {FunctionComponent} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {Grid, Typography} from "@material-ui/core";
import Clock from "./Clock/Clock";
import {Participant, RemoteParticipant} from "twilio-video";
import PlaceholderVideo from "./PlaceholderVideo";
import RoomButton from "./common/RoomButton";
import PlaceHolderRoomPic from "./PlaceholderRoomPic";
import ApplicationLanguage from "./ApplicationLanguage";
import ParticipantLoudest from "./Participant/ParticipantLoudest";

const useStyles = makeStyles((theme) => ({
    alignText: {
        textAlign: "center",
    },
    headerText: {
        margin: "1rem 0 0.5rem 0",
        textAlign: "center",
    },
    typography: {
        color: "inherit",
        width: "100%",
        maxWidth: 500,
        textAlign: "center",
    },
    details: {
        display: "flex",
        flexDirection: "column",
        paddingLeft: "16%",
    },
    langSelector: {
        display: "flex",
        alignItems: "center",
    },
    err: {
        padding: theme.spacing(2),
        marginTop: "10%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color: "red",
    },
    btnReload: {
        zIndex: 16923,
    },
    previewContainer: {
        alignItems: "center",
        display: "flex",
    },
}));

interface RoomHeaderProps {
    inRoom: boolean
    roomName: string;
    errorMsg: string;
    startTime: Date;
    endTime: Date;
    description: string;
    picture: string;
    i18nText: any;
    setI18nText: any;
    onConnectButtonClick: () => void;
    buttonText: string;
    loading: boolean;
    isRoomFull?: boolean;
    isExpired: boolean;
    dominantSpeaker: null | Participant;
}

const RoomHeader: FunctionComponent<RoomHeaderProps> = ({
                                                            inRoom,
                                                            isExpired,
                                                            roomName,
                                                            errorMsg,
                                                            startTime,
                                                            endTime,
                                                            picture,
                                                            i18nText,
                                                            setI18nText,
                                                            onConnectButtonClick,
                                                            buttonText,
                                                            loading,
                                                            isRoomFull,
                                                            dominantSpeaker,
                                                        }) => {
    const classes = useStyles();
    return (
        <React.Fragment>
            {errorMsg === "" ? (
                <>
                    <Grid container direction="row" justifyContent="center" alignItems="center">
                        <Grid container justifyContent={"center"}>
                            <Grid item container md={10} xs={9} className={classes.details}>
                                <h2 className={classes.headerText}>{roomName}</h2>
                                <Clock
                                    inRoom={inRoom}
                                    endTime={endTime}
                                    startTime={startTime}
                                    i18nText={i18nText}
                                />
                            </Grid>
                            <Grid item md={2} xs={3} className={classes.langSelector}>
                                <ApplicationLanguage
                                    handleLang={setI18nText}
                                    i18nText={i18nText}
                                />
                            </Grid>
                        </Grid>
                        <Grid item container direction="row" justifyContent="space-around">
                            <Grid item className={classes.previewContainer}>
                                {dominantSpeaker ? (
                                    <ParticipantLoudest
                                        participant={dominantSpeaker as RemoteParticipant}
                                    />
                                ) : (
                                    <PlaceholderVideo/>
                                )}
                            </Grid>
                            <Grid item>
                                <Grid container direction="column" alignItems={"center"}>
                                    <Grid item>
                                        <PlaceHolderRoomPic src={picture}/>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item container direction={"column"} alignItems={"center"}>
                            {isRoomFull && (
                                <Typography
                                    className={classes.typography}
                                    component={"h5"}
                                    variant={"h5"}
                                >
                                    {i18nText.roomFull}{" "}
                                </Typography>
                            )}
                            {window.location.pathname.length > 3 && (
                                <RoomButton
                                    fullRoom={isRoomFull}
                                    onButtonClick={onConnectButtonClick}
                                    buttonText={buttonText}
                                    loading={loading}
                                    expired={isExpired}
                                />
                            )}
                        </Grid>
                    </Grid>
                </>
            ) : (
                <Grid>
                    <div className={classes.err}>
                        <Typography color="inherit">{errorMsg}</Typography>
                    </div>
                </Grid>
            )}
        </React.Fragment>
    );
};

export default RoomHeader;
