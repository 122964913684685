import React from 'react';
import {Grid} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
        gridItem: {
            zIndex: 9999
        },
    })
);
const Helper = () => {
    const classes = useStyles()

    return (
        <div>
            <Grid className={classes.gridItem} item>
                <div  id="guides">

                    {/* TODO: add missing components: language changer, support text, participants*/}
                    {/*<LangChanger/>*/}
                    {/*<p className={classes.listItemHeavy}>{i18ntext.support}</p>*/}
                    {/*<ShowParticipants/>*/}
                </div>
            </Grid>
        </div>
    );
};

export default Helper;