import React from "react";
import avatar from "../avatar.svg";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  placeholder: {
    margin: "1em",
    height: "240px",
    width: "320px",
    border: "1px solid #06c2ff",
    justifyContent: "center",
    borderRadius: "12px",
    "& video": {
      maxWidth: "100%",
      maxHeight: "100%",
      borderRadius: "12px",
    },
  },
  placeholderImg: {
    width: "100%",
    height: "100%",
  },
}));
const PlaceholderVideo: React.FC = () => {
  const classes = useStyles();
  return (
    <div className={classes.placeholder} id="placeholderAndPreview">
      <img
        src={avatar}
        className={classes.placeholderImg}
        alt="Placeholder avatar"
      />
    </div>
  );
};

export default PlaceholderVideo;
