import React, {FC} from "react";
import {Badge, Fab, Tooltip} from "@material-ui/core";
import {Chat, Description, Mic, MicOff, ScreenShare, StopScreenShare} from "@material-ui/icons";
import {makeStyles} from "@material-ui/core/styles";
import {I18N} from "../Home";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        position: "fixed",
        bottom: "2em",
        zIndex: 9,
    },
    fab: {
        marginRight: "1.5em",
    },
    red: {
        color: "red",
    },
}));

interface RoomButtonProps {
    onMuteMicrophone: () => void;
    onShowDescription: () => void;
    onShareScreen: () => void;
    onStartChat: () => void;
    isMuted: boolean;
    connected: boolean;
    shareScreenOn: boolean;
    hasMessages: boolean;
    i18nText: I18N;
}

const RoomToolBar: FC<RoomButtonProps> = ({
                                              onStartChat, onShareScreen, onShowDescription, onMuteMicrophone,
                                              i18nText, isMuted, connected, shareScreenOn, hasMessages
                                          }) => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Tooltip
                title={
                    isMuted
                        ? Object.values(i18nText.tooltip)[5]
                        : Object.values(i18nText.tooltip)[4]
                }
            >
                <Fab
                    onClick={() => {
                        if (connected) {
                            onMuteMicrophone();
                        }
                    }}
                    color="primary"
                    className={classes.fab}
                    disabled={!connected}
                >
                    {isMuted ? <MicOff className={classes.red}/> : <Mic/>}
                </Fab>
            </Tooltip>
            <Tooltip title={Object.values(i18nText.tooltip)[1]}>
                <Fab
                    onClick={onShowDescription}
                    color="primary"
                    className={classes.fab}
                >
                    {<Description/>}
                </Fab>
            </Tooltip>
            <Tooltip title={Object.values(i18nText.tooltip)[0]}>
                <Fab
                    onClick={onStartChat}
                    color="primary"
                    className={classes.fab}
                >
                    <Badge invisible={!hasMessages} color="error" badgeContent="">
                        <Chat/>
                    </Badge>
                </Fab>
            </Tooltip>
            {connected && (
                <Tooltip title={
                    shareScreenOn ?
                        Object.values(i18nText.tooltip)[3] : Object.values(i18nText.tooltip)[2]
                }>
                    <Fab
                        onClick={onShareScreen}
                        color="primary"
                        className={classes.fab}
                    >
                        {shareScreenOn ? <StopScreenShare/> : <ScreenShare/>}
                    </Fab>
                </Tooltip>
            )}
        </div>)
}
export default RoomToolBar;
