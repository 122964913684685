import React, { FC, useEffect } from "react";

import { makeStyles } from "@material-ui/core/styles";

import { Participant as ParticipantType, RemoteAudioTrack, RemoteVideoTrack } from "twilio-video";
import { Grid } from "@material-ui/core";
import {DEVICE_KEY} from "../DeviceSelector";

const useStyles = makeStyles((theme) => ({
  videoBox: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 12,
    background: "black",
    zIndex: 9,
    maxWidth: "100%",
    "& video": {
        maxHeight: 202,
        maxWidth: "100%",
    },
  },
}));

interface ParticipantProps {
  participant: ParticipantType;
  loudSpeaker: boolean;
  local: boolean;
}

const Participant: FC<ParticipantProps> = ({
  participant,
  local,
  loudSpeaker,
}) => {
  const classes = useStyles();

  const localOrRemote = local ? "local" : "remote";
  const dominant = loudSpeaker ? "dominant" : "listener";
  const mainElem = participant.sid + "-main-" + localOrRemote + "-" + dominant;

  useEffect(() => {
    console.log(localOrRemote + " participant - fetching tracks");
    let elem = document.getElementById(mainElem) as HTMLMediaElement;
    participant.videoTracks.forEach((pub) => {
      if (pub.track != null) {
        let child = elem.appendChild(pub.track.attach());
        child.autoplay = true;
        child.controls = true;
      }
    });

    if (!local || !loudSpeaker) {
      elem.volume = 1;
      participant.audioTracks.forEach((pub) => {
        if (pub.track != null) {
          elem.appendChild(pub.track.attach());
        }
      });
    }
    participant.on("trackSubscribed", trackSubscribed);
    participant.on("trackUnsubscribed", trackUnsubscribed);
    return () => {
      participant.removeAllListeners();
    };
  }, []);

  const trackSubscribed = (track: RemoteAudioTrack | RemoteVideoTrack) => {
    if (track.kind === "audio") {
      if (local || loudSpeaker) {
        console.log("skip local audio sub");
        return;
      }
    }
    const elem = document.getElementById(mainElem) as HTMLMediaElement;
    let child = elem.appendChild(track.attach());
    child.autoplay = true;
    child.controls = track.kind === "video";
    child.volume = 1;
    const sinkId = localStorage.getItem(DEVICE_KEY.AUDIO_OUTPUT)
    // @ts-ignore
    if(child.setSinkId && sinkId){
      // @ts-ignore
      child.setSinkId(sinkId)
    }
  };

  const trackUnsubscribed = (track: RemoteVideoTrack | RemoteAudioTrack) => {
    track.detach().forEach((e) => e.remove());
  };
  return <Grid item className={classes.videoBox} id={mainElem} />;
};

export default Participant;
