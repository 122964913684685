import React, { useState, createRef, useEffect } from "react";
import SockJsClient from "react-stomp";
import linkifyHtml from "linkifyjs/html";

function Stomp(props) {
  const clientRef = createRef();
  const [state] = useState({
    id: window.location.pathname.substring(1),
    message: props.msg,
    name: props.username,
  });

  const headers = {
    destination: `/topic/chat/${state.id}`,
  };

  const handleConnect = () => {
    console.log("connected");
    props.setConnected(true);
    if (props.msg.length !== 0) {
      clientRef.current.sendMessage(
        `/topic/chat/${state.id}`,
        JSON.stringify({
          message: props.msg,
          name: props.username,
        })
      );
    }
  };

  useEffect(() => {
    if (props.msg.length !== 0) {
      clientRef.current.sendMessage(
        `/topic/chat/${state.id}`,
        JSON.stringify({
          message: linkifyHtml(props.msg),
          name: props.username,
        })
      );
    }
  }, [clientRef.current, props.msg]);

  return (
    <div>
      <SockJsClient
        url="https://api.meetups.businessmaker.fi/websocket/chat"
        topics={[`/topic/chat/${state.id}`]}
        onMessage={(msg) => props.handleReceiveMessage(msg)}
        onConnect={handleConnect}
        onDisconnect={() => console.log("connection is lost")}
        subscribeHeaders={headers}
        options={{ transports: "websocket" }}
        ref={(client) => {
          clientRef.current = client;
        }}
      />
    </div>
  );
}

export default Stomp;
