import axios from "axios";
import { connect, ConnectOptions, LocalTrack, Room } from "twilio-video";
import {getCookie} from "./components/utils";

let host = "https://api.meetups.businessmaker.fi/api";
let logsEndpoint = host + "/logs";
let audioOn = true;
const env = process.env.NODE_ENV;

// In development use 'localhost' and no audio needed
if (process.env.NODE_ENV === "development") {
  audioOn = true;
}

export const fetchToken = async (roomName: string): Promise<object> => {
  let url = host + "/rooms/" + roomName + "/join";
  if (document.cookie) {
    const sid = getCookie("sid");
    if (sid){
      url += "?sid=" + sid;
    }
  }
  try {
    const response = await axios.get(url, { withCredentials: true });
    let reply: object = {};
    if (response.headers["authorization"]) {
      const token = response.headers["authorization"].substring(7);
      reply = { ...reply, token };
    }
    if (response.headers["x-sid"]) {
      const date = new Date();
      date.setDate(date.getDate() + 1);
      document.cookie = `sid=${
        response.headers["x-sid"]
      }; expires=${date.toUTCString()}; path=/`;
    }
    reply = { ...reply, ...response.data };
    return reply;
  } catch (e) {
    axios
      .post(logsEndpoint, {
        message: "Failed to get token",
        context: {
          message: e.message,
          name: e.name,
          user: getCookie("sid"),
        },
        env,
      })
      .catch();
    throw e;
  }
};

export const joinRoom = (
  token: string,
  roomName: string,
  tracks?: (LocalTrack | MediaStreamTrack)[]
) => {
  const options: ConnectOptions = {
    name: roomName,
    dominantSpeaker: true,
    audio: audioOn,
    tracks: tracks,
    video: { height: 720, width: 1280 },
    preferredVideoCodecs: ["H264", "VP8"],
    region: "gll",
  };
  return connect(token, options)
    .then(onJoinRoom)
    .catch((e) => {
      axios
        .post(logsEndpoint, {
          message: "Failed to connect to room",
          context: {
            userAgent: navigator.userAgent,
            room: roomName,
            code: e.code,
            message: e.message,
            name: e.name,
            user: getCookie("sid"),
          },
          env,
        })
        .catch();
      throw e;
    });
};

const onJoinRoom = (room: Room) => {
  room.on("participantConnected", (participant: any) => {
    console.log(`A remote Participant connected: ${participant}`);
  });
  return room;
};

export const getChatHistory = async (roomName: string) => {
  if (roomName.length < 3) return [];
  try {
    return (await axios.get(`${host}/rooms/${roomName}/chat`)).data;
  } catch (e) {
    console.error(e);
    return [];
  }
};
