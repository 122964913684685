import React, {Dispatch, MouseEventHandler, useEffect, useState} from "react";
import {listAudioInputDevices, listAudioOutputDevices, listVideoDevices} from "../util/mediaDeviceUtils";
import {Button, Dialog, List, ListItem, ListItemText, makeStyles} from "@material-ui/core";
import {createLocalVideoTrack} from "twilio-video";
import {SettingsVoice, Speaker, SwitchVideo} from "@material-ui/icons";
import {setAllAudioToSink} from "./utils";

const useStyles = makeStyles(() => ({
    root: {
        display: "flex",
        position: "relative",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 9,
    },
}));

export enum DEVICE_KEY {
    VIDEO = "video_id",
    AUDIO_INPUT = "audio_input_id",
    AUDIO_OUTPUT = "audio_output_id",
}

type Kind = null | "microphone" | "video" | "speaker";

const DeviceSelector: React.FC = () => {
    const [open, setOpen] = useState(false);
    const [kind, setKind] = useState<Kind>(null);
    const [list, setList] = useState<MediaDeviceInfo[]>([]);
    const [audioInput, setAudioInput] = useState<MediaDeviceInfo | null>(null);
    const [video, setVideo] = useState<MediaDeviceInfo | null>(null);
    const [audioOutput, setAudioOutput] = useState<MediaDeviceInfo | null>(null);

    const [audioHasSinkId, setAudioHasSinkId] = useState<boolean>(false)

    const classes = useStyles();

    const handleClose = (e: Event, reason: string) => {
        if (reason === "escapeKeyDown" || reason === "backdropClick") {
            setOpen(false);
        }
    };

    useEffect(() => {
        const audioElem = document.createElement("audio")
        // @ts-ignore
        if (audioElem.setSinkId) {
            setAudioHasSinkId(true)
        }
    }, [])

    const handleDevices = async (listingFn: Function, kindType: Kind) => {
        return listingFn().then((listing: MediaDeviceInfo[]) => {
            setList(listing);
            setOpen(true);
            setKind(kindType);
        }).catch((e: DOMException) => {
            console.log(e)
        })
    }


    const handleAudioOutput = async () => {
        await handleDevices(listAudioOutputDevices, "speaker");
    };
    const handleVideo = async () => {
        await handleDevices(listVideoDevices, "video");
    };
    const handleAudioInput = async () => {
        await handleDevices(listAudioInputDevices, "microphone");
    };

    useEffect(() => {
        if (audioInput) {
            localStorage.setItem(DEVICE_KEY.AUDIO_INPUT, audioInput.deviceId);

        }
        setOpen(false);
    }, [audioInput]);

    useEffect(() => {
        if (video) {
            localStorage.setItem(DEVICE_KEY.VIDEO, video.deviceId);
            const elem = document.getElementById(
                "placeholderAndPreview"
            ) as HTMLElement;
            elem.removeChild(elem.firstChild as ChildNode);
            createLocalVideoTrack({
                deviceId: localStorage.getItem(DEVICE_KEY.VIDEO) as string,
            }).then((track) => {
                elem.appendChild(track.attach());
            });
        }
        setOpen(false);
    }, [video]);

    useEffect(() => {
        if (audioOutput) {
            localStorage.setItem(DEVICE_KEY.AUDIO_OUTPUT, audioOutput.deviceId);
            setAllAudioToSink(audioOutput.deviceId)
        }
        setOpen(false);
    }, [audioOutput])

    return (
        <div className={classes.root}>
            <VideoSelector click={handleVideo}/>
            <AudioInputSelector click={handleAudioInput}/>
            {
                audioHasSinkId && <AudioOutputSelector click={handleAudioOutput}/>
            }
            <DialogComponent
                list={list}
                open={open}
                videoSelector={setVideo}
                audioSelector={setAudioInput}
                speakerSelector={setAudioOutput}
                kind={kind}
                close={handleClose}
            />
        </div>
    );
};

interface SelectorProps {
    click: MouseEventHandler;
}

const VideoSelector: React.FC<SelectorProps> = ({click}) => (
    <Button variant="contained" color="primary" onClick={click}>
        <SwitchVideo/>
    </Button>
);

const AudioInputSelector: React.FC<SelectorProps> = ({click}) => (
    <Button variant="contained" color="primary" onClick={click}>
        <SettingsVoice/>
    </Button>
);

const AudioOutputSelector: React.FC<SelectorProps> = ({click}) => (
    <Button variant="contained" color="primary" onClick={click}>
        <Speaker/>
    </Button>
);

interface DialogComponentProps {
    open: boolean;
    list: MediaDeviceInfo[];
    kind: Kind;
    videoSelector: Dispatch<any>;
    audioSelector: Dispatch<any>;
    speakerSelector: Dispatch<any>;
    close: (event: Event, reason: string) => void;
}

const DialogComponent: React.FC<DialogComponentProps> = ({
                                                             close,
                                                             open,
                                                             list,
                                                             videoSelector,
                                                             audioSelector,
                                                             speakerSelector,
                                                             kind,
                                                         }) => {
    return (
        <Dialog open={open} onClose={close}>
            <DeviceList
                list={list}
                videoSelector={videoSelector}
                audioSelector={audioSelector}
                speakerSelector={speakerSelector}
                kind={kind}
            />
        </Dialog>
    );
};

interface DeviceListProps {
    list: MediaDeviceInfo[];
    kind: Kind;
    videoSelector: Dispatch<any>;
    audioSelector: Dispatch<any>;
    speakerSelector: Dispatch<any>;
}

const DeviceList: React.FC<DeviceListProps> = ({
                                                   list,
                                                   videoSelector,
                                                   audioSelector,
                                                   speakerSelector,
                                                   kind,
                                               }) => {
    let clickHandler: Dispatch<any>;
    switch (kind) {
        case "microphone":
            clickHandler = audioSelector;
            break;
        case "video":
            clickHandler = videoSelector;
            break;
        case "speaker":
            clickHandler = speakerSelector;
            break;
        default:
            break;
    }
    return (
        <List>
            {list.map((device: MediaDeviceInfo, index: number) => (
                <ListItem
                    button
                    onClick={() => clickHandler(device)}
                    key={device.deviceId + "-" + index}
                >
                    <ListItemText primary={device.label}/>
                </ListItem>
            ))}
        </List>
    );
};

export default DeviceSelector;