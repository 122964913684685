import React, { useEffect } from "react";
import {
  Grid,
  IconButton,
  InputBase,
  Paper,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CancelIcon from "@material-ui/icons/Cancel";
import SendIcon from "@material-ui/icons/Send";
import "../../App.css";
import Stomp from "./Stomp";
import Moment from "react-moment";
import AccountCircle from "@material-ui/icons/AccountCircle";
import { getChatHistory } from "../../api";
import { userColors } from "../common/userColors";
import { lightBlue } from "@material-ui/core/colors";

const useStyles = makeStyles((theme) => ({
  me: {
    float: "right",
  },
  them: {
    float: "left",
  },
  submit: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
  },
  white: {
    color: "white",
  },
  cancelIcon: {
    color: theme.color,
  },
  defaultBackGround: {
    background: theme.palette.primary.main,
  },
}));

const Chat = ({
  username,
  chatPlaceholder,
  chatOpen,
  onChatClose,
  onMsgSent,
}) => {
  const classes = useStyles();
  const [msg, setMsg] = React.useState("");
  const [messages, setMessages] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [flag, setFlag] = React.useState(false);
  const [connected, setConnected] = React.useState(false);
  const [key, setKey] = React.useState(0);
  const [chatColors, setChatColors] = React.useState([]);

  useEffect(() => {
    scrollToBottom();
  }, [messages.length]);

  useEffect(() => {
    const getMessages = async (name) => {
      let ms = await getChatHistory(name);

      // Success
      if (ms && ms.length !== 0) {
        return ms;
        // return messages.concat(ms);
      }
      // Error branch
      return [];
    };

    const roomName = window.location.pathname.substring(1);
    if (roomName.charAt(0) !== "") {
      getMessages(roomName).then(setMessages);
    }
  }, []);

  useEffect(() => {
    if (chatOpen) {
      // Create chat colors for each user
      addColors();
      handleOpenChat();
    } else {
      handleToggle();
    }
  }, [chatOpen]);

  useEffect(() => {
    if (open) {
      if (!flag) {
        setFlag(false);
        onMsgSent(true);
      }
      if (flag) {
        setFlag(false);
        onMsgSent(false);
      }
    }
    if (!open) {
      const chat = document.getElementById("chat-box");
      chat.style.display = "none";
      if (flag) {
        onMsgSent(false);
        setFlag(false);
      }
    }
  }, [open, flag]);

  const handleSubmit = (e) => {
    e.preventDefault();
    let message = document.getElementById("form").elements["chatMessage"].value;
    if (message.length > 0) {
      setMsg(message);
      message = "";
      setKey((prev) => prev + 1);
    }
  };

  const handleToggle = () => {
    if (open) {
      const chat = document.getElementById("chat-box");
      // const fab = document.getElementById("chat-fab");
      chat.style.display = "none";
      // fab.style.display = "grid";

      // Close chat so that the main component knows it
      setOpen(false);
      onChatClose();
      if (window.innerWidth < 800 && window.innerWidth > 250) {
        const guides = document.getElementById("guides");
        guides.style.display = "grid";
      }
    }
  };
  const handleReceiveMessage = (message) => {
    if (message.sid !== undefined) {
      setMessages([...messages, message]);
      setFlag(true);
    } else {
      setFlag(false);
    }
  };

  const handleOpenChat = (e) => {
    if (!open) {
      const chat = document.getElementById("chat-box");
      // const fab = document.getElementById("chat-fab");
      chat.style.display = "flex";
      // fab.style.display = "none";
      if (window.innerWidth < 800) {
        const guides = document.getElementById("guides");
        guides.style.display = "none";
      }
      setOpen(true);
    }
  };

  const scrollToBottom = () => {
    const chat = document.getElementById("chat-w");

    if (chat !== null) {
      chat.scrollTop = chat.scrollHeight;
    }
  };

  const addColors = () => {
    let chatColor = {
      user: username,
      color: userColors[0].color,
    };
    let colors = [chatColor];
    messages.forEach((chatMsg) => {
      let found = colors.find((color) => color.user === chatMsg.name);
      if (!found && colors.length < 10) {
        chatColor = {
          user: chatMsg.name,
          color: userColors[colors.length].color,
        };
        colors.push(chatColor);
      }
    });

    setChatColors([]);
    setChatColors(colors);
  };

  // Use different color for each user
  const getColor = (name) => {
    // Default color just in case
    let chatColor = lightBlue.A100;

    chatColors.forEach((color) => {
      if (color.user === name) {
        chatColor = color.color;
      }
    });
    return chatColor;
  };

  return (
    <>
      <Stomp
        msg={msg}
        setConnected={setConnected}
        handleReceiveMessage={handleReceiveMessage}
        username={username}
      />

      {/*<Tooltip title={tooltipText} placement="left-start">*/}
      {/*    <Fab*/}
      {/*        onClick={handleOpenChat}*/}
      {/*        fontSize="large"*/}
      {/*        id="chat-fab"*/}
      {/*        className={classes.fab}*/}
      {/*    >*/}
      {/*        <Badge badgeContent="" color="error" invisible={invisible}>*/}
      {/*            <ChatIcon fontSize="large" color="primary" />*/}
      {/*        </Badge>*/}
      {/*    </Fab>*/}
      {/*</Tooltip>*/}

      <div style={{ zIndex: 11 }} id="chat-box" className="chat-box">
        <div className="chat-box-header">
          <div className="toolbar">
            <Typography className={classes.white} variant="h6">
              Chat
            </Typography>
            <IconButton onClick={handleToggle} aria-label="close chat">
              <CancelIcon id="cancel-icon" className={classes.cancelIcon} />
            </IconButton>
          </div>
        </div>

        <div className="chat-box-body" id="chat-w">
          {connected &&
            messages.map((message) => {
              return (
                <Grid container key={message.sid + message.timestamp}>
                  {username !== message.name ? (
                    <Grid
                      className="chat-msg"
                      container
                      item
                      xs={9}
                      lg={9}
                      direction="row"
                      justifyContent="space-around"
                    >
                      <Grid
                        item
                        xs={2}
                        container
                        alignItems="center"
                        justifyContent="center"
                      >
                        <AccountCircle
                          style={{ color: getColor(message.name) }}
                          fontSize="large"
                        />
                      </Grid>
                      <Grid
                        item
                        xs={9}
                        container
                        direction="column"
                        justifyContent="flex-start"
                      >
                        <Typography
                          className={"chat-msg-content " + classes.white}
                          style={{ background: getColor(message.name) }}
                          variant="body2"
                        >
                          <span
                            dangerouslySetInnerHTML={{
                              __html: message.message,
                            }}
                          />
                        </Typography>
                        <span className={"chat-msg-time " + classes.them}>
                          {<Moment format="HH:mm" date={message.timestamp} />}
                        </span>
                      </Grid>
                    </Grid>
                  ) : (
                    <Grid
                      className="chat-msg-sender me"
                      container
                      item
                      xs={12}
                      lg={12}
                      justifyContent={"flex-end"}
                    >
                      <Grid item>
                        <Typography
                          className={"chat-msg-content " + classes.white}
                          style={{ background: getColor(message.name) }}
                          variant="body2"
                        >
                          <span
                            dangerouslySetInnerHTML={{
                              __html: message.message,
                            }}
                          />
                        </Typography>
                        <span className={"chat-msg-time " + classes.me}>
                          <Moment format="HH:mm" date={message.timestamp} />
                        </span>
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              );
            })}
        </div>
        <Paper
          className={classes.submit}
          component="form"
          onSubmit={handleSubmit}
          id="form"
          key={key}
        >
          <InputBase
            name="chatMessage"
            variant="outlined"
            autoComplete="off"
            className="chat-control"
            placeholder={chatPlaceholder}
          />
          <IconButton color="primary" type="submit">
            <SendIcon />
          </IconButton>
        </Paper>
      </div>
    </>
  );
};

export default Chat;
