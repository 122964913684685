import React, { FunctionComponent } from "react";
import { makeStyles } from "@material-ui/core/styles";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import { blue } from "@material-ui/core/colors";
import {
  Button,
  DialogActions,
  DialogContent,
  Typography,
  DialogProps,
} from "@material-ui/core";

const useStyles = makeStyles({
  root: {
    backgroundColor: "#06c2ff",
    color: "white !important",
    fontSize: "1.2rem",
    fontWeight: "bold",
  },
  desc: {
    backgroundColor: blue[100],
    color: blue[600],
  },
  btn: {
    color: "white",
    fontSize: 20,
  },
});

export interface RoomDescriptionProps {
  open: boolean;
  description: any;
  onShowDescription: () => void;
  i18nText: any;
}

const RoomDescription: FunctionComponent<RoomDescriptionProps> = ({
  open,
  description,
  onShowDescription,
  i18nText,
}) => {
  const classes = useStyles();
  const [scroll] = React.useState<DialogProps["scroll"]>("paper");

  return (
    <Dialog
      open={open}
      scroll={scroll}
      aria-describedby={"scroll-description"}
      onClose={onShowDescription}
      aria-labelledby="dialog-title"
    >
      <DialogTitle
        className={classes.root}
        id="simple-dialog-title"
        disableTypography
      >
        {i18nText.roomDescriptionText}
      </DialogTitle>
      <DialogContent>
        <Typography component="div" color="textSecondary" variant="body1">
          <div dangerouslySetInnerHTML={{ __html: description }} />
        </Typography>
      </DialogContent>
      <DialogActions className={classes.root}>
        <Button className={classes.btn} onClick={onShowDescription}>
          {i18nText.exit}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RoomDescription;
