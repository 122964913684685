import React, { FC } from "react";
import { Room } from "twilio-video";
import Participant from "./Participant";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
  },
}));

interface ParticipantListProps {
  room: Room;
}

const ParticipantList: FC<ParticipantListProps> = ({ room }) => {
  const classes = useStyles();

  const participants = Array.from(room.participants.values());

  return (
    <Grid container spacing={2} className={classes.root} justifyContent={"center"}>
      <Grid item xs={10} sm={10} md={4} lg={2}>
        <Participant
          participant={room.localParticipant}
          loudSpeaker={false}
          local={true}
        />
      </Grid>
      {participants.map((participant) => (
        <Grid item xs={10} sm={10} md={4} lg={2} key={participant.sid}>
          <Participant
            participant={participant}
            loudSpeaker={false}
            local={room.localParticipant.sid === participant.sid}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default ParticipantList;
