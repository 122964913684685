import React, { useEffect, useState } from "react";

// @ts-ignore
import bellAudio from "../assets/bell.mp3";
import bellIcon from "../assets/alarm-outline.svg";
import audioSetup from "../audio";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  bellActive: {
    /* Start the shake animation and make the animation last for 0.5 seconds */
    animation: "shake 0.5s",
    /* When the animation is finished, start again */
    animationiterationCount: "infinite",
  },
  bell: {
    marginLeft: "0.5em",
    height: 15,
    width: 15,
  },
}));

interface Props {
  ringTime: Date;
  inRoom: boolean;
}

const Bell: React.FC<Props> = ({ ringTime , inRoom}) => {
  const classes = useStyles();

  const [played, setPlayed] = useState(false);
  const [animate, setAnimate] = useState(false);
  const [currentTime] = useState(new Date());


  useEffect(() => {
    const playSound = (audio: any) => {
      if (!played && audio !== null && inRoom) {
        audio.src = bellAudio;
        audio.volume = 0.03;
        audio.loop = false;
        setPlayed(true);
        audio
            .play()
            .then(() => {
              audio.volume = 0.03;
              setAnimate(true);
              setTimeout(() => {
                setAnimate(false);
                audio.pause();
              }, 4000);
            })
            .catch(console.error);
      }
    };
    if (ringTime) {
      const timeUntilRingMs = ringTime.getTime() - currentTime.getTime();
      // setTimeout has maximum limit of 2^31 - 1
      const MAX_SIGNED_INT = Math.pow(2, 31) - 1;
      if (timeUntilRingMs < MAX_SIGNED_INT) {
        setTimeout(() => {
          playSound(audioSetup);
        }, timeUntilRingMs);
      }
    }
  }, [currentTime, inRoom, played, ringTime]);

  return (
    <span>
      <img
        src={bellIcon}
        alt="Bell icon"
        className={
          animate ? `${classes.bell} ${classes.bellActive}` : classes.bell
        }
      />
    </span>
  );
};
export default Bell;
