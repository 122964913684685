import React, {useEffect, useState} from "react";
import {
    createLocalAudioTrack,
    createLocalVideoTrack,
    LocalTrack,
    LocalVideoTrack,
    Participant,
    Room,
} from "twilio-video";
import english from "../i18n/en.json";
import {v4 as uuidV4} from "uuid";
import {fetchToken, joinRoom} from "../api";
import HomeScreen from "./HomeScreen";
import SpeedDialButtons from "./common/SpeedDialButtons";
import RoomHeader from "./RoomHeader";
import InitialScreen from "./InitialScreen";
import RoomDescription from "./RoomDescription";
import {translateError} from "../errors";
import DeviceSelector, {DEVICE_KEY} from "./DeviceSelector";
import useRecorder from "./useRecorder";
import MicIcon from "@material-ui/icons/Mic";
import MicOffIcon from "@material-ui/icons/MicOff";
import {Button, Grid, useMediaQuery} from "@material-ui/core";
import {getRoomDescription, getRoomName, getCookie} from "./utils";
import RoomToolBar from "./common/RoomToolBar";

export interface ParticipantDetails {
    title?: string
    username?: string
    desc?: string
}

export interface I18N {
    [key: string]: string;
}

const Home = () => {
    const [room, setRoom] = useState<Room>();
    const [loading, setLoading] = useState(false);
    const [username, setUsername] = useState<string>("");
    const [cameraOn, setCameraOn] = useState(false);
    const [connected, setConnected] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");
    const [i18nText, setI18nText] = useState<I18N>(english as any);
    const [token, setToken] = useState("");
    const [roomInfo, setRoomInfo] = useState({
        roomName: "",
        roomNameEn: "",
        roomNameFi: "",
        roomNameSe: "",
        startTime: new Date(0, 0, 0, 0),
        endTime: new Date(2030, 0, 0, 0),
        description: "",
        descriptionEn: "",
        descriptionFi: "",
        descriptionSe: "",
        picture: "",
        phone: "",
        participants: [],
    });
    const [videoTrack, setVideoTrack] = useState<LocalVideoTrack>();
    const [speedDialOpen, setSpeedDialOpen] = useState(false);
    const [chatOpen, setChatOpen] = useState(false);
    const [chatMsgInvisible, setChatMsgInvisible] = useState(true);
    const [showDescription, setShowDescription] = useState(false);
    const [shareScreenOn, setShareScreenOn] = useState(false);
    const [screenTrack, setScreenTrack] = useState<LocalVideoTrack>();
    const [screenElem, setScreenElem] = useState<HTMLElement>();
    const [dominantSpeaker, setDominantSpeaker] = useState<null | Participant>(() => {
            if (room && room.dominantSpeaker) {
                return room.dominantSpeaker;
            } else {
                return null;
            }
        }
    );

    const desktop = useMediaQuery("(min-width:900px)");
    let [audioURL, isRecording, startRecording, stopRecording] = useRecorder();
    const [isMuted, setIsMuted] = useState(false);
    //*************************************************************************
    // get RoomId pass it forward then get Token & RoomInformation
    const path = window.location.pathname;

    useEffect(() => {
        const onMount = () => {
            const params = new URLSearchParams(window.location.search);
            if (params.has("xid")) {
                const date = new Date();
                const xid = params.get("xid");
                date.setDate(date.getDate() + 1);
                document.cookie = `sid=${xid}; expires=${date.toUTCString()}; path=/`;
                window.location.search = "";
                setUsername(xid as string);

            }

            if (path.length < 3) return;
            if (path) {
                fetchToken(path.substring(1))
                    .then((res: any) => {
                        setToken(res.token);
                        setRoomInfo({
                            roomName: res.roomName,
                            roomNameEn: res.roomNameEn,
                            roomNameFi: res.roomNameFi,
                            roomNameSe: res.roomNameSe,
                            startTime: new Date(res.startTime),
                            endTime: new Date(res.endTime),
                            description: res.description,
                            descriptionEn: res.descriptionEn,
                            descriptionFi: res.descriptionFi,
                            descriptionSe: res.descriptionSe,
                            picture: res.picture,
                            phone: res.phone,
                            participants: res.participants,
                        });
                    })
                    .catch(() => {
                        setErrorMsg(i18nText.roomNotFound);
                    });
            }
        };
        onMount();
    }, [path, i18nText]);


    useEffect(() => {
        const handler = () => {
            setDominantSpeaker(room!.dominantSpeaker || room!.localParticipant);
        };
        if (room) {
            setDominantSpeaker(room.dominantSpeaker || room.localParticipant);
            room.on("dominantSpeakerChanged", handler);
        }

        window.addEventListener("beforeunload", function () {
            if (room && room.disconnect) {
                room.disconnect();
            }
        });
        return () => {
            if (room) {
                room.off("dominantSpeakerChanged", handler);
            }
        };
    }, [room]);

    useEffect(() => {
        room &&
        room.localParticipant.audioTracks.forEach((track) => {
            isMuted ? track.track.disable() : track.track.enable();
        });
    }, [isMuted, room]);

    useEffect(() => {
        if (document.cookie) {
            const sid = getCookie("sid");
            setUsername(sid);
        } else {
            setUsername(uuidV4());
        }
    }, [token]);

    const isRoomReady = () => {
        if (roomInfo.startTime) {
            const halfHourBeforeStartTime = new Date(roomInfo.startTime);
            halfHourBeforeStartTime.setMinutes(roomInfo.startTime.getMinutes() - 30);
            return halfHourBeforeStartTime <= new Date();
        }
        return false;
    };

    const isExpired = () => {
        if (roomInfo.endTime) {
            return new Date() > new Date(roomInfo.endTime);
        }
        return false;
    };
    // Join to Room
    const handleJoinRoom = async (localTracks: LocalTrack[] | undefined) => {
        const ready = isRoomReady();
        if (!ready) {
            setErrorMsg(i18nText.roomNotReady);
        }

        if (token && ready) {
            joinRoom(token, roomInfo.roomName, localTracks)
                .then((r) => {
                    setRoom(r);
                    setConnected(true);
                    setLoading(false);

                    if (window && window.scrollTo) {
                        window.scrollTo(0, 0);
                    }
                    setErrorMsg("");
                })
                .catch((err) => {
                    setLoading(false);
                    const msg = err.message;
                    if (msg === "Room expired") {
                        setErrorMsg(i18nText.expiredRoom);
                    } else {
                        const i18nError = translateError(err);
                        if (i18nError === "Unknown error:") {
                            setErrorMsg("Unknown Error: " + i18nText.errorOnJoin);
                        } else {
                            setErrorMsg(i18nText[i18nError]);
                        }
                    }
                });
        } else {
            setErrorMsg(i18nText.roomNotReady);
        }
    };

    const cameraPreview = async () => {
        try {
            const vTrack = await createLocalVideoTrack({
                name: "video",
                deviceId: localStorage.getItem(DEVICE_KEY.VIDEO) || undefined,
            });
            const audioTrack = await createLocalAudioTrack({
                name: "audio",
                deviceId: localStorage.getItem(DEVICE_KEY.AUDIO_INPUT) || undefined,
            });
            const localTracks = [vTrack, audioTrack];
            setCameraOn(true);
            setVideoTrack(vTrack);
            return localTracks;
        } catch (e) {
            setCameraOn(false);
            setErrorMsg(i18nText.errorOnMedia);
            console.error(e);
        }
    };

    const handleCameraAndRoom = async () => {
        let localTracks = await cameraPreview();
        if (path) {
            if (isRoomReady()) {
                if (!isExpired()) {
                    await handleJoinRoom(localTracks);
                } else {
                    setErrorMsg(i18nText.roomExpired);
                }
            } else {
                setErrorMsg(i18nText.roomNotReady);
            }
        }
    };

    // Connect/exit button
    const onConnectButtonClick = async () => {
        if (!room) {
            setLoading(true);
            await handleCameraAndRoom();
        } else if (room) {
            room.disconnect();
            window.location.reload();
        }
    };
    //*************************************************************************

    const onChatClose = () => {
        setChatOpen(false);
    };

    const onMsgSent = (received: boolean) => {
        setChatMsgInvisible(received);
    };
    //*************************************************************************
    // Show speed dial buttons
    const onOpenSpeedDial = () => {
        setSpeedDialOpen(true);
    };

    const onCloseSpeedDial = () => {
        setSpeedDialOpen(false);
    };

    // Handle speed dial buttons
    // Chat
    const onStartChat = () => {
        setChatOpen(!chatOpen);
    };

    // show the room Description
    const onShowDescription = () => {
        setShowDescription(!showDescription);
    };

    // sharingScreenOptions
    const displayMediaOptions = {
        video: {
            cursor: "always",
        },
        audio: true,
    };

    // Share screen
    const onShareScreen = async () => {
        if (shareScreenOn) {
            stopCapture();
        } else {
            await startCapture();
        }
        //setShareScreenOn(!shareScreenOn)
    };

    const startCapture = async () => {
        const mediaDevices = navigator.mediaDevices as any;
        await mediaDevices
            .getDisplayMedia(displayMediaOptions)
            .then((stream: any) => {
                const track = new LocalVideoTrack(stream.getTracks()[0]);
                setScreenTrack(track);
                room!.localParticipant.videoTracks.forEach((publication) => {
                    publication.unpublish();
                });
                if (stream) {
                    room &&
                    room.localParticipant
                        .publishTrack(track)
                        .then(() => {
                            // Get a reference to the parent node
                            let parentDiv = document.getElementById(
                                room.localParticipant.sid + "-main-local-listener"
                            );
                            parentDiv?.firstElementChild?.setAttribute("hidden", "true");
                            // Insert new child element before the old one
                            let elem = parentDiv?.appendChild(track.attach());
                            if (elem) {
                                elem.setAttribute("style", "width:100%");
                                elem.setAttribute("id", "share");
                                elem.setAttribute("autoplay", "true");
                                elem.setAttribute("controls", "true");
                                setScreenElem(elem);
                            }
                            setShareScreenOn(true);
                        })
                        .catch((err) => {
                            setShareScreenOn(false);
                            console.log("Error while change the publish track ", err);
                        });
                }
            });
    };

    const stopCapture = () => {
        if (room && room.localParticipant && videoTrack) {
            room.localParticipant.videoTracks.forEach((publication) => {
                publication.unpublish();
            });
            screenTrack?.stop();
            setScreenTrack(undefined);
            let childElement = document.getElementById(
                room.localParticipant.sid + "-main-local-listener"
            );

            childElement?.firstElementChild?.removeAttribute("hidden");
            screenElem?.remove();
            // Return the original track
            room.localParticipant.publishTrack(videoTrack).catch((err) => {
                console.error("Error: " + err);
            });
        }
        setScreenElem(undefined);
        setShareScreenOn(false);
    };

    const onMuteMicrophone = () => {
        setIsMuted(!isMuted);
    };

    const speedDialButtons = (
        <SpeedDialButtons
            speedDialOpen={speedDialOpen}
            onCloseSpeedDial={onCloseSpeedDial}
            onOpenSpeedDial={onOpenSpeedDial}
            onStartChat={onStartChat}
            chatMsgInvisible={chatMsgInvisible}
            onShowDescription={onShowDescription}
            isMuted={isMuted}
            onMuteMicrophone={onMuteMicrophone}
            onShareScreen={onShareScreen}
            shareScreenOn={shareScreenOn}
            chatOpen={chatOpen}
            i18nText={i18nText}
            connected={connected}
        />
    );

    const SupportNumber = (phone: string) => {
        let defaultNumber = i18nText.defaultPhoneNumber;
        let str = i18nText.support.replace("{}", defaultNumber);
        if (phone) {
            str = i18nText.support.replace("{}", phone);
        }
        return <b>{str}</b>;
    };

    return (
        <React.Fragment>
            {!cameraOn && <InitialScreen/>}
            <RoomHeader
                inRoom={!!(room && room.sid)}
                isExpired={isExpired()}
                roomName={getRoomName(roomInfo, i18nText)}
                errorMsg={errorMsg}
                startTime={roomInfo.startTime}
                endTime={roomInfo.endTime}
                description={getRoomDescription(roomInfo, i18nText)}
                picture={roomInfo.picture}
                i18nText={i18nText}
                setI18nText={setI18nText}
                onConnectButtonClick={onConnectButtonClick}
                buttonText={connected ? i18nText.exit : i18nText.joinRoom}
                loading={loading}
                dominantSpeaker={dominantSpeaker}
            />
            <p
                style={{
                    textAlign: "center",
                }}
            >
                { roomInfo.phone && SupportNumber(roomInfo.phone)}
            </p>
            {errorMsg === "" && !connected && <DeviceSelector/>}
            <HomeScreen
                room={room}
                i18nText={i18nText}
                username={username}
                chatOpen={chatOpen}
                onChatClose={onChatClose}
                onMsgSent={onMsgSent}
            />
            <RoomDescription
                open={showDescription}
                i18nText={i18nText}
                onShowDescription={onShowDescription}
                description={getRoomDescription(roomInfo, i18nText)}
            />
            {!desktop && speedDialButtons}
            {path.length <= 1 && (
                <Grid
                    container
                    alignItems={"center"}
                    direction="column"
                    justifyContent={"center"}
                    style={{zIndex: 9, margin: "8px 0"}}
                >
                    {audioURL !== "" && (
                        <audio
                            src={audioURL}
                            controls
                            style={{zIndex: 9, marginBottom: "0.5rem"}}
                        />
                    )}
                    {isRecording !== true ? (
                        <Button
                            onClick={startRecording}
                            startIcon={<MicIcon/>}
                            disabled={isRecording}
                            style={{zIndex: 9}}
                        >
                            {i18nText.startRecording}
                        </Button>
                    ) : (
                        <Button
                            onClick={stopRecording}
                            startIcon={<MicOffIcon/>}
                            disabled={!isRecording}
                            style={{zIndex: 9, backgroundColor: "red"}}
                        >
                            {i18nText.stopRecording}
                        </Button>
                    )}
                </Grid>
            )}
            {desktop && (
                <RoomToolBar
                    onMuteMicrophone={onMuteMicrophone}
                    onShowDescription={onShowDescription}
                    onShareScreen={onShareScreen}
                    onStartChat={onStartChat}
                    isMuted={isMuted}
                    connected={connected}
                    shareScreenOn={shareScreenOn}
                    i18nText={i18nText}
                    hasMessages={!chatMsgInvisible}
                />
            )}
        </React.Fragment>
    );
};

export default Home;
