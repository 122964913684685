import {useEffect, useState} from "react";
import {setAllAudioToSink} from "./utils";
import {DEVICE_KEY} from "./DeviceSelector";

const useRecorder = () => {
    const [audioURL, setAudioURL] = useState<any>("");
    const [isRecording, setIsRecording] = useState<any>(false);
    const [recorder, setRecorder] = useState<any>(null);

    useEffect(() => {
        // Lazily obtain recorder first time we're recording.
        if (recorder === null) {
            if (isRecording) {
                requestRecorder().then(setRecorder, console.error);
            }
            return;
        }

        // Manage recorder state.
        if (isRecording) {
            recorder.start();
        } else {
            recorder.stop();
        }

        // Obtain the audio when ready.
        const handleData = (e: any) => {
            setAudioURL(URL.createObjectURL(e.data));
        };

        recorder.addEventListener("dataavailable", handleData);
        return () => recorder.removeEventListener("dataavailable", handleData);
    }, [recorder, isRecording]);

    useEffect(() => {
        const sink = localStorage.getItem(DEVICE_KEY.AUDIO_OUTPUT)
        if (sink) {
            setAllAudioToSink(sink)
        }
    }, [audioURL])

    const startRecording = () => {
        setIsRecording(true);
    };

    const stopRecording = () => {
        setIsRecording(false);
    };

    return [audioURL, isRecording, startRecording, stopRecording];
};

async function requestRecorder() {
    const stream = await navigator.mediaDevices.getUserMedia({
        audio: {
            latency: 0.03
        }
    });
    let options = {
        mimeType: "audio/webm",
    }
    // @ts-ignore
    return new MediaRecorder(stream, options);
}

export default useRecorder;
